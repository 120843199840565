import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import supabase from './supabaseClient';
import { useAuth } from './AuthContext';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [totalDaysCompleted, setTotalDaysCompleted] = useState(0);
  const [taskListResetTime, setTaskListResetTime] = useState({});
  const [summaryPersistentTime, setSummaryPersistentTime] = useState({});
  const [dailyProgress, setDailyProgress] = useState([]);
  const [ongoingProgress, setOngoingProgress] = useState(0);
  const [goalCompletions, setGoalCompletions] = useState({});
  const [completionDates, setCompletionDates] = useState({});
  const timerRef = useRef(null);

  useEffect(() => {
    if (user) {
      resetState();
      fetchUserData(user.id);
    }
  }, [user]);

  const resetState = () => {
    setTasks([]);
    setActiveTaskId(null);
    setTotalDaysCompleted(0);
    setTaskListResetTime({});
    setSummaryPersistentTime({});
    setDailyProgress([]);
    setOngoingProgress(0);
    setGoalCompletions({});
    setCompletionDates({});
  };

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('user_progress')
        .select('total_days_completed, daily_progress, ongoing_progress, goal_completions')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      if (data) {
        setTotalDaysCompleted(data.total_days_completed || 0);
        setDailyProgress(data.daily_progress || []);
        setOngoingProgress(data.ongoing_progress || 0);
        setGoalCompletions(data.goal_completions || {});
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  const fetchTaskss = async () => {
    if (!user) return;

    const { data: fetchedTasks, error } = await supabase
      .from('tasks')
      .select('*')
      .eq('user_id', user.id)
      .order('id', { ascending: true });

    if (error) {
      console.error('Error fetching tasks:', error);
      return;
    }

    const updatedTasks = fetchedTasks.map(function(task) {
      let newUpdatedElapsedTime = task.elapsed_time || 0;

      if (task.start_time) {
        const startTime = new Date(task.start_time).getTime();
        const now = Date.now();
        const diffInSeconds = Math.floor((now - startTime) / 1000);
        newUpdatedElapsedTime += diffInSeconds;
      }

      const isPending = task.start_time !== null;
      const progressValue = task.duration > 0 ? (newUpdatedElapsedTime / (task.duration * 60)) * 100 : 0;
      const progress = Math.min(progressValue, 100);
      const isComplete = progress >= 100;
      let progressColorScheme = 'purple';
      if (task.status === 'GOAL') progressColorScheme = 'green';
      if (task.status === 'LIMIT') progressColorScheme = 'red';

      return {
        id: task.id,
        title: task.task_name,
        duration: task.duration,
        status: task.status,
        elapsed_time: newUpdatedElapsedTime,
        time: new Date(newUpdatedElapsedTime * 1000).toISOString().substr(11, 8),
        persistent_time: task.persistent_time || 0,
        isActive: activeTaskId === task.id,
        isPending: isPending,
        isComplete: isComplete,
        progressColorScheme: progressColorScheme,
        progress: progress,
        icon: task.icon || 'FaSun',
        iconBgColor: task.status === 'GOAL' 
  ? 'green.500' 
  : task.status === 'LIMIT' 
    ? 'red.500' 
    : 'blue.500',
        count_value: task.count_value || 0,
        persistent_count: task.persistent_count || 0
      };
    });

    setTasks(updatedTasks);
  };

  const startTask = async (taskId) => {
    if (activeTaskId !== null && activeTaskId !== taskId) {
      stopTask();
    }

    const task = tasks.find(function(t) { return t.id === taskId; });
    let initialTimeInSeconds = task ? task.elapsed_time || 0 : 0;

    await supabase
      .from('tasks')
      .update({ start_time: null })
      .eq('id', taskId);

    setActiveTaskId(taskId);
    localStorage.setItem('task_id', taskId);

    setTaskListResetTime(function(prevTimes) {
      var newTimes = {};
      for (var key in prevTimes) {
        newTimes[key] = prevTimes[key];
      }
      newTimes[task.id] = new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8);
      return newTimes;
    });

    setTasks(function(prevTasks) {
      return prevTasks.map(function(t) {
        if (t.id === taskId) {
          return { ...t, isActive: true, time: new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8) };
        }
        return t;
      });
    });

    timerRef.current = setInterval(function() {
      initialTimeInSeconds += 1;
      const newTimeString = new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8);

      setTasks(function(prevTasks) {
        return prevTasks.map(function(t) {
          if (t.id === taskId) {
            return { ...t, time: newTimeString };
          }
          return t;
        });
      });
    }, 1000);
  };

  const stopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const task = tasks.find(function(task) { return task.id === activeTaskId; });
    if (task) {
      const parts = task.time.split(':').map(Number);
      const hours = parts[0], minutes = parts[1], seconds = parts[2];
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);

      setTasks(function(prevTasks) {
        return prevTasks.map(function(t) {
          if (t.id === activeTaskId) {
            return { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false };
          }
          return t;
        });
      });

      setActiveTaskId(null);
    }
  };

  const upgradedStopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const task = tasks.find(function(task) { return task.id === activeTaskId; });
    if (task) {
      const parts = task.time.split(':').map(Number);
      const hours = parts[0], minutes = parts[1], seconds = parts[2];
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);

      setTasks(function(prevTasks) {
        return prevTasks.map(function(t) {
          if (t.id === activeTaskId) {
            return { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false };
          }
          return t;
        });
      });

      setActiveTaskId(null);
      localStorage.removeItem('task_id');
    }
  };

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.hidden) {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          const { data: currentTask, error } = await supabase
            .from('tasks')
            .select('start_time')
            .eq('id', taskId)
            .single();

          if (error) {
            console.error('Error fetching task:', error);
            return;
          }

          if (currentTask && !currentTask.start_time) {
            const timestamp = new Date().toISOString();
            const { error: updateError } = await supabase
              .from('tasks')
              .update({ start_time: timestamp })
              .eq('id', taskId);

            if (updateError) {
              console.error('Error updating start_time:', updateError);
              return;
            }
          }
          stopTask();
        }
      } else {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          await fetchTaskss();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [stopTask, startTask, activeTaskId]);

  const updateElapsedTimeInDatabase = async (taskId, elapsedTime) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .update({ elapsed_time: elapsedTime })
        .eq('id', taskId);

      if (error) {
        console.error('Error updating elapsed time:', error);
      } else {
        console.log("Elapsed time for task ID " + taskId + " updated to " + elapsedTime + " seconds");
      }
    } catch (err) {
      console.error('Error in updateElapsedTimeInDatabase:', err);
    }
  };

  useEffect(() => {
    if (activeTaskId !== null) {
      timerRef.current = setInterval(() => {
        setTasks(function(prevTasks) {
          return prevTasks.map(function(task) {
            if (task.id === activeTaskId) {
              var timeStr = taskListResetTime[task.id] || "00:00:00";
              var timeParts = timeStr.split(':').map(Number);
              var h = timeParts[0] || 0;
              var m = timeParts[1] || 0;
              var s = timeParts[2] || 0;
              var totalSeconds = h * 3600 + m * 60 + s + 1;

              if (!isNaN(totalSeconds)) {
                const newTimeString = new Date(totalSeconds * 1000).toISOString().substr(11, 8);

                setTaskListResetTime(function(prevTimes) {
                  var newTimes = {};
                  for (var key in prevTimes) {
                    newTimes[key] = prevTimes[key];
                  }
                  newTimes[task.id] = newTimeString;
                  return newTimes;
                });

                var persistStr = summaryPersistentTime[task.id] || "00:00:00";
                var persistParts = persistStr.split(':').map(Number);
                var persistH = persistParts[0] || 0;
                var persistM = persistParts[1] || 0;
                var persistS = persistParts[2] || 0;
                var totalPersistSeconds = persistH * 3600 + persistM * 60 + persistS + 1;
                if (!isNaN(totalPersistSeconds)) {
                  const newPersistTimeString = new Date(totalPersistSeconds * 1000).toISOString().substr(11, 8);
                  setSummaryPersistentTime(function(prevTimes) {
                    var newObj = {};
                    for (var k in prevTimes) {
                      newObj[k] = prevTimes[k];
                    }
                    newObj[task.id] = newPersistTimeString;
                    return newObj;
                  });
                }

                updateTimeInDatabase(task.id, 1);
                return { ...task, time: newTimeString };
              }
            }
            return task;
          });
        });

        const relevantTasks = tasks.filter(function(t) { return t.status === 'GOAL' || t.status === 'COUNT'; });
        if (relevantTasks.length > 0) {
          var totalFraction = 0;
          for (var i=0; i<relevantTasks.length; i++) {
            var t = relevantTasks[i];
            if (t.status === 'GOAL') {
              var fraction = t.duration > 0 ? Math.min(t.elapsed_time / (t.duration * 60), 1) : 0;
              totalFraction += fraction;
            } else if (t.status === 'COUNT') {
              var fracCount = (t.duration > 0) ? Math.min(t.count_value / t.duration, 1) : 0;
              totalFraction += fracCount;
            }
          }
          var overallProgress = (totalFraction / relevantTasks.length) * 100;
          setOngoingProgress(overallProgress);
        } else {
          setOngoingProgress(0);
        }
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [activeTaskId, tasks]);

  const updateTimeInDatabase = async (taskId, seconds) => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .select('elapsed_time')
        .eq('id', taskId)
        .single();

      if (error) {
        console.error('Error fetching task time:', error);
        return;
      }

      var currentElapsedTime = data ? data.elapsed_time || 0 : 0;
      var newElapsedTime = currentElapsedTime + seconds;

      const { error: updateError } = await supabase
        .from('tasks')
        .update({ elapsed_time: newElapsedTime })
        .eq('id', taskId);

      if (updateError) console.error('Error updating task time:', updateError);
    } catch (err) {
      console.error('Error in updateTimeInDatabase:', err);
    }
  };

  const completeDay = async () => {
    stopTask();
    await new Promise(function(resolve) { setTimeout(resolve, 100); });
    if (activeTaskId !== null) {
      setActiveTaskId(null);
    }

    // Calculate today's final progress from GOAL and COUNT tasks
    const relevantTasks = tasks.filter(function(t) { return t.status === 'GOAL' || t.status === 'COUNT'; });
    var totalFraction = 0;
    if (relevantTasks.length > 0) {
      for (var i=0; i<relevantTasks.length; i++) {
        var rt = relevantTasks[i];
        if (rt.status === 'GOAL') {
          var rtTimeStr = taskListResetTime[rt.id] || "00:00:00";
          var rtParts = rtTimeStr.split(':').map(Number);
          var hh = rtParts[0] || 0;
          var mm = rtParts[1] || 0;
          var ss = rtParts[2] || 0;
          var elapsedToday = Math.min(hh * 3600 + mm * 60 + ss, rt.duration * 60);
          var goalFraction = rt.duration > 0 ? (elapsedToday / (rt.duration * 60)) : 0;
          totalFraction += Math.min(goalFraction, 1);
        } else if (rt.status === 'COUNT') {
          var countFraction = rt.duration > 0 ? Math.min((rt.count_value || 0) / rt.duration, 1) : 0;
          totalFraction += countFraction;
        }
      }
    }
    var currentProgress = (relevantTasks.length > 0) ? (totalFraction / relevantTasks.length) * 100 : 0;

    var newDailyProgress = dailyProgress.slice();
    newDailyProgress.push(currentProgress);
    var newTotalDaysCompleted = totalDaysCompleted + 1;
    var newGoalCompletions = {};
    for (var gcKey in goalCompletions) {
      newGoalCompletions[gcKey] = goalCompletions[gcKey];
    }

    // Update tasks persistent_time and reset counts
    await Promise.all(tasks.map(async function(task) {
      var elapsedTime = task.elapsed_time || 0;
      var persistentTime = task.persistent_time || 0;
      var newPersistentTime = elapsedTime + persistentTime;

      if (task.status === 'GOAL' && elapsedTime >= task.duration * 60) {
        newGoalCompletions[task.id] = (newGoalCompletions[task.id] || 0) + 1;
      }

      var updates = { elapsed_time: 0, persistent_time: newPersistentTime };
      if (task.status === 'COUNT') {
        updates.count_value = 0;
        updates.persistent_count = (task.persistent_count || 0) + (task.count_value || 0);
      }

      const { error: updateError } = await supabase
        .from('tasks')
        .update(updates)
        .eq('id', task.id);

      if (updateError) {
        console.error('Error updating tasks on complete day:', updateError);
      }
    }));

    if (user && user.id) {
      const { error: userProgressError } = await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          total_days_completed: newTotalDaysCompleted,
          daily_progress: newDailyProgress,
          ongoing_progress: 0,
          goal_completions: newGoalCompletions
        });

      if (userProgressError) {
        console.error('Error saving user progress data:', userProgressError);
      } else {
        console.log("User progress successfully saved.");
      }
    } else {
      console.error("User ID is null or undefined, skipping progress save.");
    }

    setDailyProgress(newDailyProgress);
    setTotalDaysCompleted(newTotalDaysCompleted);
    setGoalCompletions(newGoalCompletions);
    setCompletionDates(completionDates + 1);

    setTaskListResetTime({});
    setTasks(function(prevTasks) {
      return prevTasks.map(function(task) {
        return {
          ...task,
          time: '00:00:00',
          isActive: false,
          elapsed_time: 0
        };
      });
    });

    setActiveTaskId(null);
    setOngoingProgress(0);
    clearInterval(timerRef.current);
  };

  return (
    <TimerContext.Provider value={{
      tasks: tasks,
      setTasks: setTasks,
      activeTaskId: activeTaskId,
      startTask: startTask,
      stopTask: stopTask,
      totalDaysCompleted: totalDaysCompleted,
      taskListResetTime: taskListResetTime,
      summaryPersistentTime: summaryPersistentTime,
      completeDay: completeDay,
      dailyProgress: dailyProgress,
      ongoingProgress: ongoingProgress,
      goalCompletions: goalCompletions,
      completionDates: completionDates,
      setCompletionDates: setCompletionDates,
      setTotalDaysCompleted: setTotalDaysCompleted,
      setDailyProgress: setDailyProgress,
      setOngoingProgress: setOngoingProgress,
      setActiveTaskId: setActiveTaskId,
      updateElapsedTimeInDatabase: updateElapsedTimeInDatabase,
      setGoalCompletions: setGoalCompletions,
      fetchTaskss: fetchTaskss,
      upgradedStopTask: upgradedStopTask
    }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);

import React from 'react'; 
import { HStack, VStack, Box, Text, Badge, IconButton, Progress } from '@chakra-ui/react';
import { FaPlay, FaPause, FaSun, FaMoon, FaStar, FaCloud, FaPlus } from 'react-icons/fa';
import { useTimer } from '../TimerContext'

const icons = {
  FaSun,
  FaMoon,
  FaStar,
  FaCloud,
};

const TaskItem = ({ task, onStart, onStop, onEdit, onDelete, onIncrement, progress }) => {
  const { upgradedStopTask } = useTimer();
  const { title, time, status, iconBgColor, isActive, icon, isPending, isComplete, progressColorScheme, count_value } = task; 
  const IconComponent = icons[icon];

  const activeBorderStyle = isActive 
    ? '2px solid gold' 
    : isPending 
      ? '2px solid orange' 
      : 'none';

  return (
<VStack
  spacing={0}
  bg={
    isComplete 
      ? (status === 'GOAL' ? 'green.400' : 
        status === 'LIMIT' ? 'red.400' : 
        status === 'COUNT' ? 'blue.400' : // ADD this line for COUNT completion green
        'blue.400') 
      : "gray.700"
  }
      p={4}
      borderRadius="lg"
      w="100%"
      justifyContent="space-between"
      onClick={onEdit}
      cursor="pointer"
      position="relative"
      border={activeBorderStyle}
      boxShadow={isActive ? '0 0 10px gold' : 'none'}
    >
      <HStack spacing={4} w="100%" justifyContent="space-between">
        <HStack spacing={4}>
          <Box bg={iconBgColor} p={2} borderRadius="full">
            <Box as={IconComponent} color="white" boxSize="1.5em" />
          </Box>
          <VStack align="flex-start" spacing={1}>
            <Text fontSize="md" fontWeight="bold">{title}</Text>
            <Badge colorScheme={status === 'GOAL' ? 'green' : status === 'LIMIT' ? 'red' : 'purple'}>{status}</Badge>
          </VStack>
        </HStack>
        {status === 'COUNT' ? (
          <VStack spacing={1} alignItems="flex-end">
            <Text fontSize="sm" color="gray.400">Count: {count_value}</Text>
            <IconButton
              icon={<FaPlus />}
              colorScheme="purple"
              variant="outline"
              aria-label="Increment Count"
              onClick={(e) => { e.stopPropagation(); onIncrement(); }}
            />
          </VStack>
        ) : (
          <VStack spacing={1} alignItems="flex-end">
            <Text fontSize="sm" color={isComplete ? "white" : "gray.400"}>{time}</Text>
            <IconButton
              icon={isActive ? <FaPause /> : <FaPlay />}
              colorScheme="purple"
              variant="outline"
              aria-label={isActive ? "Pause Task" : "Start Task"}
              onClick={(e) => { e.stopPropagation(); isActive ? upgradedStopTask() : onStart(); }}
            />
          </VStack>
        )}
      </HStack>
      <Progress
        value={progress}
        size="sm"
        colorScheme={status === 'COUNT' ? 'blue' : progressColorScheme}
        w="100%"
        position="absolute"
        bottom="0"
        borderBottomRadius="lg"
      />
    </VStack>
  );
};

export default TaskItem;
